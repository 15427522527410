// 页面自适应js
$(function(){
	function response(){
		//     分辨率小于960px时, .w 添加 .w-xs
		// 分辨率960px到1210px时, .w 添加 .w-sm
		//分辨率1210px到1390px时, .w 添加 .w-md
		//    分辨率大于1390px时, .w 添加 .w-lg
		$(window).resize(function(){
			var w = $(".w");
			var windowWidth = $(this).width();
			if(windowWidth < 960){
				w.removeClass("w-sm w-md w-lg").addClass("w-xs");
			}else if(windowWidth >=  960 && windowWidth < 1210){
				w.removeClass("w-xs w-md w-lg").addClass("w-sm");
			}else if(windowWidth >= 1210 && windowWidth < 1390){
				w.removeClass("w-xs w-sm w-lg").addClass("w-md");
			}else{
				w.removeClass("w-xs w-sm w-md").addClass("w-lg");
			}
		});
	}
	response();
	// 添加 .sm-hidden 的元素在网页分辨率低于960时会隐藏
	function hideDisplay(){
		//窗口大小改变时,带 .sm-hidden 的元素在窗口小于960px时隐藏,大于960时显示
		var smHidden = $(".t1-sm-hidden");
		$(window).resize(function(){
			var windowWidth = $(this).width();
			if(windowWidth < 960){
				smHidden.css("display", "none")
			}else{
				smHidden.css("display", "block")
			}
		});
	}
	hideDisplay();
	$(window).resize();


	// 设置主体内容区域大小
	// 主体区域有侧边栏时,主体内容宽度 = 主体宽度-侧边栏宽度-20px, 20px是主体内容与侧边栏之间的间距
	function t1SetMainWidthFn(){
		$(window).resize(function() {
			$(".t1-main-content").each(function(){
				var $parent = $(this).parents(".w");
				var $sidebar = $(this).siblings(".t1-main-sidebar");
				if($sidebar && $sidebar.is(":visible")){
					$(this).css("width", ($parent.width() - $sidebar.width() - 20) + "px");
				}else{
					$(this).css("width", $parent.width() - 2 + "px");
				}
			});
		});
		$(window).resize();
	}
	t1SetMainWidthFn();
});


(function ($) {
	"use strict";
	$.fn.nsw = function (options) {
		this.defaults = {
			getCodeBtn: '#getcode_gg', //验证图片id
			btnCell: '.submit', //提交按钮
			mainCell: '.bd',

			row: '.row',
			timeInterval: 30,
			isValidate: false,
			ts: null
		};
		var opt = $.extend({}, this.defaults, options);
		var defaultObj = $(this);

		//验证函数
		var verify = function verify() {
			var service = new Object(null);
			service.run = function run(type, value, msg) {
				switch (type) {
					case 'phone':
						return service.checkPhone(value);
					case 'email':
						return service.checkEmail(value);
					case 'number':
						return service.checkNumber(value);
					case '':
						return service.required(value);
					default:
						return service.checkReg(type, value, msg);
				}
			};
			service.checkPhone = function checkPhone(value) {
				var phone = /^(13[0-9]|14[0-9]|15[0-9]|18[0-9])\d{8}$/;
				return {
					flog: phone.test(value),
					msg: '请填写正确的手机号码！'
				};
			};
			service.checkEmail = function checkEmail(value) {
				var email = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
				return {
					flog: email.test(value),
					msg: '请填写正确的email！'
				};
			};
			service.checkReg = function checkReg(type, value, msg) {
				var reg = new RegExp(type);
				return {
					flog: reg.test(value),
					msg: msg
				};
			};
			service.required = function required(value, lable) {
				if (!value.length) {
					return {
						flog: false,
						msg: lable + '不能为空！'
					};
				} else {
					return {
						flog: true
					};
				}
			};
			service.checkForm = function checkForm(obj) {
				var msg = obj.attr('msg') ? obj.attr('msg') : '请正确输入!';
				var pattern = obj.attr('pattern');
				var label = obj.parent().siblings('.row-hd').html();
				if (label) {
					label = label.replace("：", '');
				}
				if (obj.attr('required')) {
					var requiredReturn = Verify.required(obj.val(), label);
					if (!requiredReturn.flog) {
						obj.siblings('.nsw-formtipbd').find('span').show().html(requiredReturn.msg);
						obj.addClass('err');
						return false;
					}
				}
				if (obj.val() !== '') {
					var returnInfo = Verify.run(pattern, obj.val(), msg);
					if (!returnInfo.flog) {
						obj.siblings('.nsw-formtipbd').find('span').show().html(returnInfo.msg);
						obj.addClass('err');
						return false;
					} else {
						obj.removeClass('err');
						obj.siblings('.nsw-formtipbd').find('span').hide();
						return true;
					}
				}
			};
			service.checkSelect = function checkSelect(obj) {
				var label = obj.parent().siblings('.row-hd').html();
				label = label.replace("：", '');
				if (obj.attr('required') && obj.val() === '-1') {
					obj.siblings('.nsw-formtipbd').find('span').show().html(label + "需要选择!");
					obj.addClass('err');
					return false;
				} else {
					obj.removeClass('err');
					obj.siblings('.nsw-formtipbd').find('span').hide();
				}
				return true;
			};
			service.checkAll = function checkAll(inputAndTextarea, selects) {
				var flog = true;
				var checkInputAndTextarea = inputAndTextarea.each(function () {
					var obj = $(this);
					flog = Verify.checkForm(obj) && flog;
				});
				var checkSelect = selects.each(function () {
					var obj = $(this);
					flog = Verify.checkSelect(obj) && flog;
				});
				return flog;
			};
			service.countdown = function countdown(time) {
				if (!!!$(opt.btnCell).siblings('b').length) {
					opt.ts = $(opt.btnCell).parent().append(" <b></b>");
				}
				if (time > 0) {
					time--;
					opt.ts.find("b").html("请" + time + "秒后再次提交！");
					$(opt.btnCell).attr("disabled", "disabled");
					setTimeout(function () {
						service.countdown(time);
					}, 1000);

				} else {
					//ts.find("b").html("");
					$(opt.btnCell).removeAttr("disabled");
					opt.ts.find("b").hide();
				}
			};

			return service;
		};
		var Verify = new verify();
		/*事件绑定
		 * a.验证码刷新
		 * */
		//刷新验证码(先保留默认路径,看后期是否会进行优化)
		defaultObj.find(opt.getCodeBtn).click(function () {
			console.log("getCode");
			$(this).attr("src", 'Tools/code/code_gg.php?' + Math.random());
		});
		//监控input[text]  和select失去焦点后触发验证
		opt.inputAndTextarea = defaultObj.find(opt.row + " input[type='text']," + opt.row + "  textarea")
		opt.inputAndTextarea.blur(function () {
			var obj = $(this);
			Verify.checkForm(obj);
		});
		//select选择后
		opt.selects = defaultObj.find(opt.row + " select");
		opt.selects.change(function () {
			var obj = $(this);
			Verify.checkSelect(obj);
		});
		//提交表单
		defaultObj.find(opt.btnCell).click(function () {
			if (!!opt.isValidate) {
				var codegg = true;
			} else {
				var code_gg = $("#code_gg").val();
				codegg = false;
				$.ajax({
					type: "post",
					url: "/Tools/code/chk_code.php?act=gg",
					data: "code=" + code_gg,
					async: false,
					success: function (msg) {
						if (msg === '1') {
							codegg = true;
							$("#getcode_gg").attr("src", 'Tools/code/code_gg.php?' + Math.random());
						} else {
							nsw.showTip("验证码错误!");
							$("#getcode_gg").attr("src", 'Tools/code/code_gg.php?' + Math.random());
							codegg = false;
						}
					}
				});
			}

			if (Verify.checkAll(opt.inputAndTextarea, opt.selects) && codegg) {
				var dt = $("#intentionalOrderFormId").serializeObject();
				var url = "";
				if ($("input[name='vcenter']")) {
					url = $("input[name='vcenter']").val();
				}
				$.ajax({
					'url': url + "?json=" + JSON.stringify(dt),
					'type': 'post',
					'dataType': 'jsonp',
					'jsonpCallback': 'callback',
					'error': function (data) {
						nsw.showTip("提交失败!");
						console.log(data);
					},
					'success': function (data) {
						nsw.showTip(data.msg);
						Verify.countdown(opt.timeInterval);
					}
				});

			}

		});

	};
	$.fn.serializeObject = function () {
		var o = {};
		var a = this.serializeArray();
		$.each(a, function () {
			if (o[this.name]) {
				if (!o[this.name].push) {
					o[this.name] = [o[this.name]];
				}
				o[this.name].push(this.value || '');
			} else {
				o[this.name] = this.value || '';
			}
		});
		return o;
	};
	var $tipHtml = $('<div class="modal-body showTip"> <p>msg</p> </div>');
	//var $messageHtml=$('<div class="modal-body"> <p>msg</p> </div>');
	var $modal_wrap = $('<div class="modal-shade">');
	$modal_wrap.height($(window).height()).css({
		display: 'none',
		width: '100%',
		zIndex: 1080,
		position: 'fixed',
		top: '0px',
		left: '0px',
		minHeight: '800px'
	});
	$tipHtml.css({
		margin: "100px auto 0",
		width: "300px",
		color: "#f08300",
		background: "rgba(196,197,202,0.6)",
		lineHeight: '60px',
		height: '60px',
		padding: '0 15px',
		fontSize: "16px"
	});
	$tipHtml.find("p").css({
		textAlign: "center"
	});
	window.nsw = {
		$showModal: function showModal() {

		},
		showTip: function showTip(msg) {
			$modal_wrap.append($tipHtml);
			$tipHtml.find("p").html(msg);
			$("body").append($modal_wrap);
			$modal_wrap.fadeIn();
			setTimeout(function () {
				/* $("body").remove($(".modal_wrap"));*/
				$modal_wrap.remove();
			}, 3000);
		}
	};
})($);