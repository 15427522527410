;!$(function(){

	// 主导航
	function t1mainNavFn(){
		$(".t1-first-menu").hover(function(){
			$(this).find(".t1-second-panel").stop().slideDown(200);
		},function(){
			$(this).find(".t1-second-panel").stop().slideUp(200);
		});
	}
	t1mainNavFn();

	// 副导航2
	function t1ViceNavFn2(){
		//获取副导航导航项
		var viceNav = $(".t1-vice-nav");
		var viceNav2 = $(".t1-vice-nav-2");
		var viceNav2Menu = viceNav2.find(".t1-menu");
		viceNav2.find(".t1-menu-hd").click(function(){
			$(this).siblings(".t1-menu-bd").slideToggle(300);
			return false;
		});
	}
	t1ViceNavFn2();

	// 副导航3
	function t1ViceNavFn3(){
		var viceNav = $(".t1-vice-nav");
		var viceNav3 = $(".t1-vice-nav-3");
		var viceNav3Menu = viceNav3.find(".t1-menu");
		viceNav3Menu.hover(function(){
			$(this).addClass("t1-current")
			       .find(".t1-menu-bd").show();
		},function(){
			$(this).removeClass("t1-current")
			       .find(".t1-menu-bd").hide();
		});
	}
	t1ViceNavFn3();

	//商品直通车（设置商品直通车dl列表高度）
	function t1CommodityTrainFn1(){
		//纵向商品直通车 dl 的高度等于他父元素的内高度
		var linkList1 = $(".t1-link-list-1");
		linkList1.find("dl").height(linkList1.innerHeight() - 40 + "px");
	}
	t1CommodityTrainFn1();


	//商品直通车（设置商品直通车dl列表高度）
	function t1CommodityTrainFn2(){
		//窗口大小变化时，横向排列商品直通车 dt 高度等于他直接父元素 dl 的高度
		var linkList2 = $(".t1-link-list-2");
		$(window).resize(function(){
			linkList2.find("dt").each(function(){
				$(this).height($(this).parents("dl").height());
			});
		}).resize();
	}
	t1CommodityTrainFn2();



	// 页签切换
	function t1TabToggleFn(){
		var tab = $(".t1-tab");
		var switchable = tab.find(".t1-switchable");
		switchable.click(function(){
			var parent = $(this).parents(".t1-tab");
			var inx = parent.find(".t1-switchable").index($(this));
			$(this).addClass("t1-current").siblings(".t1-switchable").removeClass("t1-current");
			parent.find(".t1-tab-panel").hide().eq(inx).show();
		});
		switchable.eq(0).click();
	}
	t1TabToggleFn();

	// 点击用户评论里的回复按钮时,显示回复面板,再次点击隐藏
	function t1CommentFn(){
		var comment = $(".t1-comment");
		var interact = comment.find(".t1-interact");
		interact.find(".t1-fore2").click(function(){
			var parent = $(this).parents("li");
			parent.find(".t1-input-group").slideToggle(200);
		});
	}
	t1CommentFn();

	//面包屑导航,部分页面面包屑有查看更多按钮
	//鼠标悬停时显示下拉面板,离开时隐藏下拉面板
	function t1CrumbFn(){
		var crumb = $(".t1-crumb");
		var moreCase = crumb.find(".t1-more-case");
		moreCase.hover(function(){
			$(this).find(".t1-more-case-bd").stop().slideDown(150);
		},function(){
			$(this).find(".t1-more-case-bd").stop().slideUp(150);
		});
	}
	t1CrumbFn();




	function addFavorite2() {
		var url = window.location;
		var title = document.title;
		var ua = navigator.userAgent.toLowerCase();
		if (ua.indexOf("360se") > -1) {
			alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
		} else if (ua.indexOf("msie 8") > -1) {
			window.external.AddToFavoritesBar(url, title); //IE8
		} else if (document.all) {
			try {
				window.external.addFavorite(url, title);
			} catch (e) {
				alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
			}
		} else if (window.sidebar) {
			window.sidebar.addPanel(title, url, "");
		} else {
			alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
		}
	}
});

function QQLink(qq){
	qq = qq ? qq:QQ;
	window.open("http://wpa.qq.com/msgrd?v=3&uin="+qq+"&site=qq&menu=yes");
}


